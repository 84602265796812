import React from "react";
import { Button as Button_, ButtonProps, useStyleConfig } from "@chakra-ui/react";
import { LinkProps } from "next/link";
import SharedNextTranslateLink from "src/shared/components/Controls/SharedNextTranslateLink";
import * as Texts from "src/shared/components/Texts";
import * as consts from "src/shared/consts";

export const Button = ({ children, ...buttonProps }: ButtonProps) => {
  const { size, variant, color } = buttonProps;
  const gradient = `linear-gradient(90deg, #FF2D55 0%, #28D4FF 100%)`;
  const gradientHover = `linear-gradient(90deg, #FF1441 0%, #00CCFF 100%);`;

  const styleConfig = {
    baseStyle: {
      h: "65px",
      px: "30px",
      maxW: "100%",
      whiteSpace: "normal",
    },
    variants: {
      cta: {
        bg: gradient,
        color: "white",
        _hover: {
          bg: gradientHover,
          color: color,
        },
      },
      primary: {
        bg: gradient,
        color: "white",
        _hover: {
          bg: gradientHover,
          color: color,
        },
      },
      secondary: {
        bg: consts.COLOR_MEDIUM_BLUE,
        color: "white",
        _hover: {
          bg: "#BBD1F2",
        },
      },
    },
    defaultProps: {
      variant: "primary",
    },
  };

  const styles = useStyleConfig("Button", { size, variant, styleConfig });
  const Text = variant === "cta" ? Texts.Cta1 : Texts.NormalCaps;

  return (
    <Button_ sx={styles} {...buttonProps} borderRadius={"0"}>
      <Text as={"div"}>{children}</Text>
    </Button_>
  );
};

interface SharedButtonLinkProps extends ButtonProps {
  href: LinkProps["href"];
  asPath?: LinkProps["as"];
  shallow?: LinkProps["shallow"];
}

export const ButtonLink = ({ children, href, asPath, shallow, ...buttonProps }: SharedButtonLinkProps) => {
  return (
    <SharedNextTranslateLink href={href} as={asPath} shallow={shallow}>
      <Button {...buttonProps}>{children}</Button>
    </SharedNextTranslateLink>
  );
};
